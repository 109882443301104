<!--Start topbar header-->
<header class="topbar-nav">
	<nav class="navbar navbar-expand fixed-top">
		<div class="toggle-menu" (click)="toggleSidebar()"> <i class="zmdi zmdi-menu"></i>
		</div>
		<div class="search-bar flex-grow-1">
			<div class="input-group">
				<div class="input-group-prepend search-arrow-back">
					<button class="btn btn-search-back" type="button"><i class="zmdi zmdi-long-arrow-left"></i>
					</button>
				</div>
				<input type="text" class="form-control" placeholder="поиск">
				<div class="input-group-append">
					<button class="btn btn-search" type="button"><i class="zmdi zmdi-search"></i>
					</button>
				</div>
			</div>
		</div>
		<ul class="navbar-nav align-items-center right-nav-link ml-auto">
			<li class="nav-item dropdown search-btn-mobile">
				<a class="nav-link position-relative" href="javascript:;"> <i class="zmdi zmdi-search align-middle"></i>
				</a>
			</li>
			<li ngbDropdown class="nav-item dropdown-lg">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic1" ngbDropdownToggle> <i class="zmdi zmdi-comment-outline align-middle"></i><span class="bg-danger text-white badge-up">12</span>
				</a>
				<div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
					<ul class="list-group list-group-flush">
						<li class="list-group-item d-flex justify-content-between align-items-center">Новые сообщения <a></a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-5.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Антонина Ивановна</h6>
										<p class="msg-info">Благодарю за трактор...</p> <small>Сегодня, 16:10</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-6.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Елизавета Матвеевна</h6>
										<p class="msg-info">Смарт контракт согласован...</p> <small>Сегодня, 8:30</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-7.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Аристарх Павлович</h6>
										<p class="msg-info">Техника на трале доставлена...</p> <small>20/01/2022, 13:50</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-8.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Семен Андреевич</h6>
										<p class="msg-info">Машины заправлены...</p> <small>20/01/2021, 12:50</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item text-center"><a href="javaScript:;">Все сообщения</a>
						</li>
					</ul>
				</div>
			</li>
			<li ngbDropdown class="nav-item dropdown-lg">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic2" ngbDropdownToggle> <i class="zmdi zmdi-notifications-active align-middle"></i><span class="bg-info text-white badge-up">14</span>
				</a>
				<div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu-right">
					<ul class="list-group list-group-flush">
						<li class="list-group-item d-flex justify-content-between align-items-center">Действия <a></a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media"> <i class="zmdi zmdi-accounts fa-2x mr-3 text-info"></i>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Добавить нового сотрудника</h6>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media"> <i class="zmdi zmdi-coffee fa-2x mr-3 text-warning"></i>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Отправить сотрудника в отпуск</h6>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media"> <i class="zmdi zmdi-notifications-active fa-2x mr-3 text-danger"></i>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Срочные оповещения</h6>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item text-center"><a href="javaScript:;">Продолжить</a>
						</li>
					</ul>
				</div>
			</li>
			<li ngbDropdown class="nav-item language"> <a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic3" ngbDropdownToggle><i class="flag-icon flag-icon-ru align-middle"></i></a>
				<ul ngbDropdownMenu aria-labelledby="dropdownBasic3" class="dropdown-menu-right">
					<li ngbDropdownItem><i class="flag-icon flag-icon-gb mr-3"></i><a href="http://en.stereosharing.com" style="text-decoration: none">English</a></li>
					<li ngbDropdownItem><i class="flag-icon flag-icon-ru mr-3"></i><a href="http://stereosharing.com/" style="text-decoration: none">Russia</a></li>
				</ul>
			</li>
			<li ngbDropdown class="nav-item">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic4" ngbDropdownToggle> <span class="user-profile"><img src="assets/images/avatars/avatar-2.png" class="img-circle" alt="user avatar"></span>
				</a>
				<ul ngbDropdownMenu aria-labelledby="dropdownBasic4" class="dropdown-menu-right">
					<li class="dropdown-item user-details">
						<a href="javaScript:;">
							<div class="media">
								<div class="avatar">
									<img class="align-self-start mr-3" src="assets/images/avatars/avatar-2.png" alt="user avatar">
								</div>
								<div class="media-body">
									<h6 class="mt-2 user-title">Смирнов Денис Петрович</h6>
									<p class="user-subtitle">info@stroi.com</p>
								</div>
							</div>
						</a>
					</li>
			
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-comments mr-3" onclick='document.getElementById("box1").hidden = false;'></i>20 Есть обеспечение</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-balance-wallet mr-3" onclick='document.getElementById("box1").hidden = true;'></i>4 Нет обеспечения</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-settings mr-3"></i>Настройки</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-power mr-3"></i>Выйти</li>
				</ul>
			</li>
		</ul>
	</nav>
</header>
<!--End topbar header-->